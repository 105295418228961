import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { checkPriority } from "../utils/TicketsTableFunctions";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import {
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import UserIcon from "../components/UserIcon";
import { Grid } from "@mui/system";
import { ChipWithMenu } from "../components/TeamsCountChips";
import {
  capitalizeFirstLetter,
  capitalizeWords,
} from "./clearAllSelectedFilters";
import CloseIcon from "@mui/icons-material/Close";
import sanitizeHtml from "sanitize-html";

const tagChips = (value) => {
  const displayValues = value?.slice(0, 2);
  const remainingValues = value?.slice(2);
  return (
    <Grid container spacing={1}>
      {displayValues.map((key, index) => (
        <Grid key={index} className="ticket_tag">
          {key}
        </Grid>
      ))}
      {remainingValues.length > 0 ? (
        <ChipWithMenu
          menuItemList={remainingValues}
          count={remainingValues.length}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export const updateTicketConfig = ({
  type,
  value,
  listData,
  updateFunction,
  showClear,
  setShowClear,
  focusType,
  setFocusType,
}) => {
  if (!value?.length && !["priority", "sub_team"].includes(type)) {
    return <Typography style={{ color: "#9b9fb1" }}>Empty</Typography>;
  }

  const handleFocus = () => {
    setFocusType(type);
    setShowClear(true);
  };

  const handleBlur = () => {
    setFocusType(null);
    setShowClear(false);
  };
  const handleInputChange = (e, type, updateFunction, listData) => {
    if (type === "sub_team") {
      const selectedItem = listData?.find(
        (item) => item.name === e.target.value
      );
      return updateFunction(selectedItem || null);
    }

    updateFunction(e.target.value);
  };

  const commonStyles = {
    width: "100%",
    borderRadius: "4px",
    padding: "0.2rem",
    "& .MuiInputBase-root::before, & .MuiInputBase-root::after": {
      display: "none",
    },
    "& .MuiOutlinedInput-root": {
      maxHeight: "36px !important",
      fontSize: "14px",
      color: "#3c3e49",
    },
    "& .MuiSelect-select span::before": {
      content: '"Empty"',
      color: "#9b9fb1",
      fontSize: "0.9rem",
      fontFamily: "Rubik",
    },
    "& .MuiSelect-icon": { display: "none" },
    "&:hover": { backgroundColor: "#F8F9FA" },
  };

  const renderTextField = (optionsMapper) => (
    <TextField
      data-testid="select"
      select
      variant="standard"
      sx={commonStyles}
      onMouseEnter={handleFocus}
      onMouseLeave={handleBlur}
      slotProps={{
        input: {
          endAdornment:
            showClear && value && focusType === type && type !== "priority" ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => updateFunction("")}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null,
        },
      }}
      value={value || ""}
      onChange={(e) => handleInputChange(e, type, updateFunction, listData)}
      fullWidth
    >
      {listData.map(optionsMapper)}
    </TextField>
  );

  switch (type) {
    case "status":
      return renderTextField((item) => (
        <MenuItem key={item.id} value={item.name} sx={{ padding: "0.8rem" }}>
          <Typography>{capitalizeWords(item.name)}</Typography>
        </MenuItem>
      ));

    case "assignee":
      return listData.length > 0 ? (
        <UserIcon
          image_url={listData[0].url}
          username={listData[0].username}
          colorCode={listData[0].color_code}
        />
      ) : (
        <Typography style={{ color: "#9b9fb1" }}>Empty</Typography>
      );

    case "text":
      return <Typography>{value}</Typography>;

    case "priority":
      return renderTextField((item) => (
        <MenuItem key={item.id} value={item.name} sx={{ padding: "0.8rem" }}>
          {checkPriority(item.name, true)}
        </MenuItem>
      ));

    case "tag":
      return tagChips(value);

    case "sub_team":
      return listData.length > 0 ? (
        renderTextField((item) => (
          <MenuItem key={item.id} value={item.name} sx={{ padding: "0.5rem" }}>
            <Typography>{item.name}</Typography>
          </MenuItem>
        ))
      ) : (
        <Typography style={{ color: "#9b9fb1" }}>Empty</Typography>
      );

    default:
      return <Typography style={{ color: "#9b9fb1" }}>Empty</Typography>;
  }
};

export const renderSwitch = (type, value, listData) => {
  switch (type) {
    case "assignee":
      return (
        <UserIcon
          image_url={listData[0]["url"]}
          username={listData[0]["username"]}
          colorCode={listData[0]["color_code"]}
        />
      );
    case "text":
      return <Typography>{value}</Typography>;
    case "priority":
      return checkPriority(value, true);
    case "tag":
      return tagChips(value);
    case "status":
    case "assignee_added":
    case "assignee_removed":
    case "assignee_changed":
    case "custom_field_updated":
      return <Typography>{capitalizeFirstLetter(value)}</Typography>;

    default:
      return <Typography>Empty</Typography>;
  }
};

export const iconsMap = {
  status_changed: <RadioButtonCheckedOutlinedIcon fontSize="small" />,
  assignee_added: <PersonOutlineOutlinedIcon fontSize="small" />,
  assignee_changed: <PersonOutlineOutlinedIcon fontSize="small" />,
  assignee_removed: <PersonOutlineOutlinedIcon fontSize="small" />,
  priority_changed: <TourOutlinedIcon fontSize="small" />,
  custom_field_updated: <RadioButtonCheckedOutlinedIcon fontSize="small" />,
  team: <PeopleAltOutlinedIcon fontSize="small" />,
  sub_team_updated: <PeopleAltOutlinedIcon fontSize="small" />,
  issue_category: <FolderOpenOutlinedIcon fontSize="small" />,
  due_date_changed: <CalendarTodayOutlinedIcon fontSize="small" />,
  tag: <LocalOfferOutlinedIcon fontSize="small" />,
};

export const customFieldsIconsMap = {
  url: <LanguageIcon fontSize="small" />,
  dropdown: <ArrowDropDownSharpIcon fontSize="small" />,
  text: <TextFieldsIcon fontSize="small" />,
  email: <MailOutlineOutlinedIcon fontSize="small" />,
};

export const customFieldsIcons = {
  "L1 escalation notes": <TextFieldsIcon fontSize="small" />,
  "L2 escalation notes": <TextFieldsIcon fontSize="small" />,
  "Ide url": <LanguageIcon fontSize="small" />,
  "Ninja email id": <MailOutlineOutlinedIcon fontSize="small" />,
  "Pm dashboard url": <LanguageIcon fontSize="small" />,
  "Sensei email id": <MailOutlineOutlinedIcon fontSize="small" />,
  "Infra pipeline url": <LanguageIcon fontSize="small" />,
};

export const sanitizedHtml = (data) =>
  sanitizeHtml(data, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ["src", "width"],
      p: ["style", "data-block-id"],
    },
  });
