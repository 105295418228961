import { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Grid2 as Grid,
  Skeleton,
} from "@mui/material";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import "./style.css";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useTicketDetail } from "../../context/TicketDetailContext";

const TicketDescription = ({ title, displayBtn, buttonTitle, description }) => {
  const { isLoading } = useTicketDetail();

  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [description]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="description">
      <div className="title_button">
        <Typography
          style={{
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        <Button
          style={{
            display: displayBtn,
            width: "9rem",
            backgroundColor: "rgba(243, 235, 255, 1)",
            marginLeft: "1rem",
            borderRadius: "8px",
            color: "#000",
            textTransform: "none",
            justifyContent: "space-around",
          }}
        >
          <ReplayOutlinedIcon />
          {buttonTitle}
        </Button>
      </div>

      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={70} />
      ) : (
        <Box
          className={`description_box ${isExpanded ? "expanded" : "collapsed"}`}
          style={{
            transition: "max-height 0.3s ease, height 0.3s ease",
            position: "relative",
          }}
        >
          <Grid
            style={{
              padding: "1rem 1rem 2rem 2rem",
              paddingBottom: "4rem",
              height: isExpanded ? contentHeight : "10vh",
              maxHeight: contentHeight,
              overflow: "hidden",
            }}
            ref={contentRef}
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {description}
            </ReactMarkdown>
          </Grid>
          {contentHeight > 150 && (
            <Grid className="showContent" onClick={toggleExpand}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isExpanded ? (
                  <ExpandLessOutlinedIcon />
                ) : (
                  <ExpandMoreOutlinedIcon />
                )}
                <span>{isExpanded ? "Show less" : "Show more"}</span>
              </div>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
};

export default TicketDescription;
