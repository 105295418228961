import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import "./style.css";
import { Skeleton, Typography } from "@mui/material";
import { updateTicketConfig } from "../../utils/TicketDetailsCommonFunction";
import { getTicketConfigDetails } from "./TicketConfigDetailsFunctions";
import { useTicketDetail } from "../../context/TicketDetailContext";
import { useEffect, useMemo } from "react";

const TicketConfigDetails = () => {
  const {
    ticketData,
    subTeams,
    isLoading,
    showClear,
    setShowClear,
    focusType,
    setFocusType,
    fetchSubTeams,
    updatePriority,
    updateSubTeam,
    incomingWSData
  } = useTicketDetail();

  const options = useMemo(
    () =>
      getTicketConfigDetails(
        ticketData,
        subTeams,
        updatePriority,
        updateSubTeam,
        incomingWSData
      ),
    [ticketData, subTeams, updatePriority, updateSubTeam]
  );

  useEffect(() => {
    if (ticketData?.team_id) {
      fetchSubTeams(ticketData.team_id);
    }
  }, [ticketData.team_id]);

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={170} />;
  }

  const containerStyles = {
    width: "100%",
    fontFamily: "Rubik",
    marginBottom: "1.5rem",
  };
  const headerStyles = {
    display: "flex",
    alignItems: "center",
    color: "rgba(103, 107, 126, 1)",
    justifyContent: "space-between",
  };
  const iconContainerStyles = { display: "flex", alignItems: "center" };
  const configContainerStyles = {
    width: "60%",
    fontSize: "0.9rem",
    fontFamily: "Rubik",
  };

  return (
    <Box sx={containerStyles}>
      <Grid container rowSpacing={2} columnSpacing={{ sm: 8, md: 12, lg: 16 }}>
        {options.map((item) => (
          <Grid key={item.id} item xs={6} className="options">
            <div style={headerStyles}>
              <div style={iconContainerStyles}>
                <item.icon
                  style={{ marginRight: "0.5rem", fontSize: "1.4rem" }}
                />
                <Typography>{item.name}</Typography>
              </div>
            </div>
            <div style={configContainerStyles}>
              {updateTicketConfig({
                type: item.type,
                value: item.value,
                listData: item.listData,
                updateFunction: item.updateFunction,
                showClear,
                setShowClear,
                focusType,
                setFocusType,
              })}
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TicketConfigDetails;
