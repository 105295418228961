import "./styles.css";
import { useState, useEffect } from "react";
import ComingSoon from "../ComingSoon";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Dashboard from "../Dashboard";
import Teams from "../Teams";
import EditTeam from "../EditTeam";
import Users from "../Users";
import AddUser from "../AddUser";
import ChangeShift from "../ChangeShift";
import HeaderDrawer from "../../components/HeaderDrawer";
import TicketSummaryScreen from "../TicketSummaryScreen";
import Automation from "../Automation";
import AddEditAutomation from "../AddEditAutomation";
import TicketsDetailsPage from "../TicketsDetails";

const MainApp = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  useEffect(() => {}, [location]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <HeaderDrawer
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/tickets" element={<TicketSummaryScreen />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/notifications" element={<ComingSoon />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/teams/edit-team" element={<EditTeam />} />
          <Route path="/teams/add-team" element={<EditTeam />} />
          <Route path="/teams/view-team" element={<EditTeam />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add-user" element={<AddUser />} />
          <Route path="/users/edit-user" element={<AddUser />} />
          <Route path="/users/change-shift" element={<ChangeShift />} />
          <Route path="/automations" element={<Automation />} />
          <Route
            path="/automations/add-automation"
            element={<AddEditAutomation />}
          />
          <Route
            path="/automations/edit-automation"
            element={<AddEditAutomation />}
          />

          <Route path="/tickets/:ticket_id" element={<TicketsDetailsPage />} />
        </Routes>
      </HeaderDrawer>
    </div>
  );
};

export default MainApp;
