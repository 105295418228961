import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TicketConfigDetails from "../TicketConfigDetails";
import TicketDescription from "../TicketDescription";
import TicketCustomFields from "../TicketFields";
import { useNavigate } from "react-router-dom";
import { Skeleton, Tooltip } from "@mui/material";
import { useTicketDetail } from "../../context/TicketDetailContext";

const TicketInfo = () => {
  const { ticketData, isLoading } = useTicketDetail();
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.length > 10) {
      navigate(-1);
    } else {
      navigate("/tickets");
    }
  };

  return (
    <Grid
      sx={{
        height: "100vh",
        padding: "1.5rem",
        overflowY: "scroll",
        scrollbarWidth: "none",
      }}
    >
      <Grid>
        <Grid
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            backgroundColor: "white",
            height: "auto",
            padding: "0.9rem 0.9rem",
            zIndex: 1,
            width: "50%",
          }}
        >
          <Button
            onClick={goBack}
            sx={{
              padding: "0 !important",
              minWidth: 0,
              marginRight: "1rem",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ArrowBackIcon
              fontSize="medium"
              sx={{
                color: "rgba(103, 107, 126, 1)",
              }}
            />
          </Button>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={50} />
          ) : (
            <Tooltip title={ticketData.title}>
              <Typography
                sx={{
                  fontSize: "1.5rem !important",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {ticketData.title}
              </Typography>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid style={{ marginTop: "3rem", padding: "1rem 1rem" }}>
        <TicketConfigDetails />
        <TicketDescription
          title="Description"
          displayBtn="none"
          description={ticketData.description}
        />
        <TicketDescription
          title="Suggested resolution"
          description={ticketData.suggested_resolution}
          displayBtn="none"
        />
        <TicketCustomFields
          tableTitle="Escalation notes"
          data={ticketData.escalation_notes}
        />
        <TicketCustomFields
          tableTitle="Custom Fields"
          data={ticketData.task_meta_data}
        />
      </Grid>
    </Grid>
  );
};

export default TicketInfo;
