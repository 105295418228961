import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { getSubTeamList, returnWebSocketUrl } from "../../services/apiServices";
import {
  customAPICallWithPayload,
  customFetch,
} from "../../services/requestServices";
import { errorNotify } from "../../components/ToastComponent";
import { useParams } from "react-router-dom";
import useWebSocketService from "../../services/useWebSocketProvider";

const TicketContext = createContext();
export const useTicketDetail = () => useContext(TicketContext);

export const TicketProvider = ({ children }) => {
  const { ticket_id } = useParams();
  const [ticketData, setTicketData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [subTeams, setSubTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [focusType, setFocusType] = useState(null);
  const [incomingWSData,setIncomingWSData] = useState([]);

  const prevFetchUrl = useRef(null);

  const url = `${baseURL}v2/ticket/${ticket_id}`;
  const subTeamUrl = `${baseURL}v1/sub_team`;

  const [activityUrl, setActivityUrl] = useState(
    `${baseURL}v1/task_audit/task_activity_logs/?ticket_id=${ticket_id}&limit=10`
  );

  // Websocket connection for activity data
  const ActivityDataWebSocketUrl = returnWebSocketUrl(baseURL, ticket_id);
  const ActivityDataWSResponse = useWebSocketService(ActivityDataWebSocketUrl);

  const fetchTicketData = async () => {
    setIsLoading(true);

    const data = await customFetch(url, "GET", null);
    setTicketData(data);

    setIsLoading(false);
  };

  const fetchActivityData = async (customUrl = activityUrl) => {
    if (customUrl === prevFetchUrl.current || !customUrl) return;
    prevFetchUrl.current = customUrl;
    setIsLoadingLogs(true);

    const data = await customFetch(customUrl, "GET", null);
    setActivityData([...activityData, ...data.results]);

    if (data?.next != null) setActivityUrl(data.next);
    else setActivityUrl("");

    setIsLoadingLogs(false);
  };

  const fetchSubTeams = async (teamId) => {
    setIsLoading(true);

    const data = await getSubTeamList(teamId, subTeamUrl);
    setSubTeams(data);

    setIsLoading(false);
  };

  const updatePriority = async (priority) => {
    const updateUrl = `${url}/update_priority/`;
    const response = await customAPICallWithPayload(updateUrl, "PATCH", {
      priority,
    });

    if (response === "success") {
      setTicketData((prev) => ({ ...prev, priority }));
    } else {
      errorNotify(`Failed to update priority. Please try again.`);
    }
  };

  const updateSubTeam = async (subTeam) => {
    console.log(subTeam, "update");
    const updateUrl = `${url}/update_subteam/`;
    const response = await customAPICallWithPayload(updateUrl, "PATCH", {
      sub_team_id: subTeam.id || "",
    });

    if (response === "success") {
      setTicketData((prev) => ({ ...prev, sub_team: subTeam.name }));
    } else {
      errorNotify(`Failed to update sub-team. Please try again.`);
    }
  };

  const valueObj = useMemo(
    () => ({
      ticketData,
      activityData,
      subTeams,
      isLoading,
      isLoadingLogs,
      showClear,
      setShowClear,
      focusType,
      setFocusType,
      fetchActivityData,
      fetchSubTeams,
      updatePriority,
      updateSubTeam,
      incomingWSData
    }),
    [ticketData, activityData, isLoading, isLoadingLogs, showClear, focusType,incomingWSData]
  );

  useEffect(() => {
    fetchTicketData();
    fetchActivityData();
  }, [ticket_id]);

  useEffect(() => {
    if (ActivityDataWSResponse !== null) {
      try {
        const newData = ActivityDataWSResponse?.data;
        if (newData && typeof newData === "object") {
          setActivityData((prevActivityData) => [newData, ...prevActivityData]);
          setIncomingWSData(newData)
        } else {
          throw new Error("Invalid data structure received");
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error, {
          ActivityDataWSResponse,
          errorMessage: error.message,
        });
      }
    }
  }, [ActivityDataWSResponse]);

  return (
    <TicketContext.Provider value={valueObj}>{children}</TicketContext.Provider>
  );
};
